<template>
	<div id="cpm_orderList">
		<div class="el-content mb12">
			<div class="kd-pacel">搜索</div>
			<div class="mb12">
				<span class="f12">订单状态 ：</span>
				<a-radio-group v-model:value="search.order_status" button-style="solid" @change="getOrderList(1,info.limit)">
					<a-radio-button value="-1">全部</a-radio-button>
					<a-radio-button value="0">待支付</a-radio-button>
					<a-radio-button value="1">待发货</a-radio-button>
					<a-radio-button value="2">待收货</a-radio-button>
					<a-radio-button value="3">已完成</a-radio-button>
					<a-radio-button value="7">申请退款</a-radio-button>
					<a-radio-button value="4">已退款</a-radio-button>
					<a-radio-button value="5">已取消</a-radio-button>
					<a-radio-button value="6">回收站</a-radio-button>
				</a-radio-group>
			</div>
			<div class="mb12">
				<span class="f12">配送/门店：</span>
				<a-space>
					<a-select style="width: 200px;" v-model:value="search.recovery_method" placeholder="请选择配送方式">
						<a-select-option value="">请选择配送方式</a-select-option>
						<a-select-option :value="1">快递</a-select-option>
						<a-select-option :value="2">自提</a-select-option>
					</a-select>
					<a-select style="width: 200px;"
						v-model:value="search.outlet_id"
						placeholder="请选择门店"
						v-if="$store.state.modulesPerm.salesroom == 1">
						<a-select-option value="">请选择门店</a-select-option>
						<a-select-option v-for="(item,index) in outlet" :key="index" :value="item.id">
							{{item.name}}
						</a-select-option>
					</a-select>
				</a-space>
			</div>
			<div class="mb12">
				<span class="f12">下单 时间：</span>
				<a-range-picker v-model:value="search.searchTime"/>
			</div>
			<div class="mb12" style="display: flex;align-items: center;">
				<a-space style="display: flex;justify-content: space-between;width: 100%;">
					<div>
						<span class="f12">关 键 字 ：</span>
						<a-input-search
							style="width: 400px;"
							v-model:value="search.key"
							placeholder="订单编号、收货人、联系电话"
							enter-button
							@search="getOrderList(1,info.limit)"
						/>
					</div>
					<a-space>
						<a-button type="primary" @click="exportState.show=true">
                            <i class="ri-download-line"></i>导出
                        </a-button>
						<a-button
							v-if="$store.state.modulesPerm.salesroom == 1"
							type="primary"
							@click="exportOutLetOrder">
							<i class="ri-download-line"></i>导出门店售卖信息
						</a-button>
						<com-print-face-order
							btn-type="primary"
							:type="1"
							:order-id="face.order_id">
						</com-print-face-order>
						<com-upload-btn :custom-upload="true" btnText="批量发货" @uploadChange="uploadChange"></com-upload-btn>
						<a v-if="isShop == 0" style="font-size: 13px;margin-left: 8px;" href="https://www.yuque.com/docs/share/18c1da28-b5e5-4a80-bfd8-63f2c8eac4c5?#" target="_blank">商城订单批量发货说明</a>
					</a-space>
				</a-space>
			</div>
		</div>

		<div class="el-content">
			<a-row class="table-header">
				<a-col :span="6"><div class="bg-purple">商品</div></a-col>
				<a-col :span="4" style="text-align: center;"><div class=" bg-purple-light">买家</div></a-col>
				<a-col :span="3" style="text-align: center;"><div class=" bg-purple"></div>支付方式/时间</a-col>
				<a-col :span="4" style="text-align: center;"><div class=" bg-purple-light">金额</div></a-col>
				<a-col :span="3" style="text-align: center;"><div class=" bg-purple"></div>状态</a-col>
				<a-col :span="4" style="text-align: center;"><div class=" bg-purple-light">操作</div></a-col>
			</a-row>
			<a-checkbox-group v-model:value="face.order_id" style="width: 100%;">
				<div class="order-list" v-for="(item,index) in info.list" :key="index">
					<a-row class="table-container-header">
						<a-col :span="24">
							<div class="order-table-th">
								<a-checkbox :value="item.id" style="margin-left: 12px;"></a-checkbox>
								<a-tag color="#FF0066" class="th-tag">商城</a-tag>
								<span class="th-create-time">{{item.create_time}}</span>
								<span class="th-order-number">订单编号：{{item.order_number}}(自增id：{{item.id}})</span>
								<a-tag class="th-tag" color="#0099FF" v-if="item.recovery_method==2">自提</a-tag>
								<a-tag class="th-tag" color="#FF0099" v-if="item.is_card>0">礼品卡兑换</a-tag>
								<div class="th-opration">
									<span  v-has="{action:'wxapp_order_remark',plat:isShop}">
										<com-set-remark
											btn-text="备注"
											btn-type="link"
											remark-type="goods-order"
											postApi="doSomethingByOrder"
											:remarkValue="item.manager_remark"
											:params="{
												pk:item.id,
												type:5 ,
												order_type:1
											}">
										</com-set-remark>
									</span>
									<router-link :to="{path:isShop ==0 ?'/order/detail':'/many_store/order/detail',query:{id:item.id}}">
										<a-button type="link" v-has="{action:'/order/detail',plat:isShop}">详情</a-button>
									</router-link>
								</div>
							</div>
						</a-col>
					</a-row>
					<a-row class="table-container">
						<a-col :span="6" class="td-container" >
							<div class=" bg-purple" style="display: flex;margin-bottom: 10px;" v-for="(val,ind) in item.detail" :key="ind">
								<a-image class="th-cover" :src="val.snap_goods.cover"/>
								<div class="goods-name">{{val.goods_name}}</div>
								<div class="buy-count">
									<p>￥{{val.price}}</p>
									<p>x {{val.count}}</p>
								</div>
							</div>
						</a-col>
						<a-col :span="4"  class="td-container td-info">
							<div class="bg-purple">
								<div v-if="item.user" class="kd-active-user">
									<a-tooltip>
										<template #title>
											<div>
												<div style="display: flex;align-items: center;">头像：<a-avatar :src="item.user.avatar_url" /></div>
												<div>昵称：{{item.user.nickname}}</div>
												<div>真实姓名：{{item.user.true_name}}</div>
												<div>使用金额：{{item.user.use_money}}</div>
												<div>支付订单数：{{item.user.pay_order_number}}</div>
											</div>
										</template>
										<a-tag style="cursor: pointer;" color="#6699FF">{{item.user.nickname}}</a-tag>
									</a-tooltip>
								</div>
								<p>{{item.name}} </p>
								<p>{{item.mobile}}</p>
							</div>
						</a-col>
						<a-col :span="3"  class="td-container td-info">
							<div class="bg-purple" >
								<div v-if="item.pay_method">
									<div>{{$util.payMethod(item.pay_method)}}</div>
									<div>{{item.pay_time}}</div>
								</div>
								<p v-else>未支付</p>
								<span v-if="item.order_status == 1 && item.is_refund== 1">
									<com-order-refund :orderid="item.id" @change="getOrderList(info.page,info.limit)"></com-order-refund>
								</span>
							</div>
						</a-col>
						<a-col :span="4"  class="td-container td-info">
							<div class="bg-purple">
								<div v-if="item.coupon_price > 0"><span class="f12">优惠券：</span>-{{item.coupon_price}}元</div>
								<div v-if="item.manager_discount"><span class="f12">商家优惠：</span>-{{item.manager_discount}}元</div>
								<div>
									<span class="f12">总价：</span><span class="kdred">{{item.total_price}}元 </span>
									<span v-if="item.order_status == 0 && item.is_cancel !=1"
                                          class="up-address"
                                          @click="showUpdatePrice(index)"> 修改价格
                                    </span>
								</div>
								<div v-if="item.order_status != 0">
                                    <span class="f12">实付：</span>
                                    <span class="kdred">{{item.true_price}}元</span>
                                </div>
							</div>
						</a-col>
						<a-col :span="3"  class="td-container td-info">
							<div class="bg-purple">
								<p :style="{color:$status.getShopOrderStaus(item,true)}">
                                    {{$status.getShopOrderStaus(item)}}
                                </p>

								<!-- 配送员信息 -->
								<div v-if="item.runing">
									<a-tooltip>
										<template #title>
											<template v-if="item.runing.run_type == 'dada'">
												<div>配送员：{{item.runing.dm_name}}</div>
												<div>配送员电话：{{item.runing.dm_mobile}}</div>
											</template>
											<template v-else>
												<div v-if="item.runing.persion">配送员：{{item.runing.persion.name}}</div>
												<div v-if="item.runing.persion">配送员电话：{{item.runing.persion.mobile}}</div>
											</template>
											<div>取货时间：{{item.runing.pickup_time || '--'}}</div>
											<div>送达时间：{{item.runing.confirm_time || '--'}}</div>
											<div v-if="item.runing.cancel_reason">取消理由：{{item.runing.cancel_reason}}</div>
										</template>
										<a-tag color="#00CC66" v-if="item.runing.run_type == 'ziying'">商家配送</a-tag>
										<a-tag color="#0099FF" v-if="item.runing.run_type == 'dada'">达达配送</a-tag>
									</a-tooltip>
									<p v-if="item.runing.persion">配送员：{{item.runing.persion.name}} ,{{item.runing.persion.mobile}}</p>
									<div v-if="item.runing.cancel_reason" style="color: red;">取消理由：{{item.runing.cancel_reason}}</div>
								</div>
							</div>
						</a-col>
						<a-col :span="4"  class="td-container td-info">
							<div class=" bg-purple">
								<a-space>
									<a-button size="small" type="danger"
										v-if="$status.getShopOrderBtnAuth(item,'recycleBin')"
										v-has="{action:'wxapp_order_recycle',plat:isShop}"
										@click="changeOrderStatus('intoRecycle',item.id)">移入回收站
									</a-button>
									<a-button size="small" type="danger"
										v-if="$status.getShopOrderBtnAuth(item,'recycleBinOut')"
										v-has="{action:'wxapp_order_recycle',plat:isShop}"
										@click="changeOrderStatus('outRecycle',item.id)">移出回收站
									</a-button>
									<a-button size="small" type="danger"
										v-if="$status.getShopOrderBtnAuth(item,'del')"
										v-has="{action:'wxapp_order_del',plat:isShop}"
										@click="changeOrderStatus('delete',item.id)">删除
									</a-button>
									<a-button size="small"
										v-if="$status.getShopOrderBtnAuth(item,'cancel')"
										v-has="{action:'wxapp_order_cancel',plat:isShop}"
										@click="changeOrderStatus('cancel',item.id)">取消订单
									</a-button>
									<a-button size="small" type="primary"
										v-if="$status.getShopOrderBtnAuth(item,'confirm') && isShop == 0"
										v-has="{action:'wxapp_order_confirm',plat:isShop}"
										@click="changeOrderStatus('confirm',item.id,3)">确认收货
									</a-button>
									<template v-if="$status.getShopOrderBtnAuth(item,'delivery')">
										<com-deliver-goods
											btn-type="primary"
											btn-size="small"
											:id="item.id"
											:type="1"
											:is-shop="isShop"
											@success="()=>{getOrderList(info.page,info.limit)}">
										</com-deliver-goods>
									</template>
									<template v-if="$status.getShopOrderBtnAuth(item,'delivery') && isShop == 0">
										<com-print-face-order
											btn-type="primary"
											btn-size="small"
											:type="1"
											:order-id="item.id"
											@close="getOrderList(info.page,info.limit)">
										</com-print-face-order>
									</template>
								</a-space>
								<!--
									if( order.runing &&  order.runing.run_type == 'dada' ){
										if( ['5','9','10','1000'].includes(order.runing.order_status) ){
											this.deliveryForm.reback = true  //dada重新下单
										}
									}
								-->
							</div>
						</a-col>
					</a-row>

					<!-- 收货地址 -->
					<a-row class="table-container-header">
						<a-col :span="24">
							<div class="order-table-th">
								<span style="margin:0 5px 0 20px;">{{item.name}}</span>
								<span style="margin:0 5px;">{{item.mobile}}</span>{{item.address}}
								<!-- 门店信息 -->
								<span style="margin-left: 10px;color: #bf1c02;" v-if="item.outlet">【配送门店 : {{item.outlet.name}} 门店地址：{{item.outlet.address}}】</span>
							</div>
						</a-col>
					</a-row>
					<a-row class="table-container-header" v-if="item.remark && item.remark !='undefined'">
						<a-col :span="24">
							<div class="order-table-th">
								<span style="margin:0 5px 0 20px;">备注：{{item.remark}}</span>
							</div>
						</a-col>
					</a-row>
				</div>
			</a-checkbox-group>

			<div class="pager">
				<a-pagination
					show-size-changer
					:default-current="info.page"
					:total="info.count"
					@showSizeChange="(p,e)=>{getOrderList(info.page,e)}"
					@change="(e)=>{getOrderList(e,info.limit)}"
				/>
			</div>
		</div>

		<a-modal title="订单导出"
                 v-model:visible="exportState.show"
                 width="500px"
                 @ok="exportOrder"
                 @cancel="exportState.show=false"
        >
			<a-checkbox @change="outCheckAll">全选</a-checkbox>
			<a-checkbox-group v-model:value="exportState.outCheckList">
				<a-checkbox value="order_number">订单编号</a-checkbox>
			    <a-checkbox value="total_price">订单总价</a-checkbox>
			    <a-checkbox value="order_status">订单状态</a-checkbox>
			    <a-checkbox value="name">收货人</a-checkbox>
			    <a-checkbox value="address">收货地址</a-checkbox>
			    <a-checkbox value="mobile">联系电话</a-checkbox>
			    <a-checkbox value="create_time">下单时间</a-checkbox>
			    <a-checkbox value="pay_method">支付方式</a-checkbox>
			    <a-checkbox value="pay_time">支付时间</a-checkbox>
			    <a-checkbox value="manager_remark">卖家备注</a-checkbox>
			    <a-checkbox value="manager_discount">商家优惠</a-checkbox>
			    <a-checkbox value="send_time">发货时间</a-checkbox>
			    <a-checkbox value="confirm_time">收货时间</a-checkbox>
			    <a-checkbox value="goods_info">商品信息</a-checkbox>
			</a-checkbox-group>
		</a-modal>
		<a-modal v-model:visible="uopState.show"
                 title="修改优惠金额"
                 width="500px"
                 @ok="saveOrderPrice"
                 @cancel="uopState.show=false">
			<a-input type="text" placeholder="请输入优惠价格" v-model:value="uopState.manager_discount"></a-input>
		</a-modal>
	</div>
</template>

<script>
import comSetRemark from '@/components/miniapp/com-set-remark.vue'
import comDeliverGoods from '@/components/miniapp/com-deliver-goods.vue'
import comPrintFaceOrder from '@/components/miniapp/com-print-face-order.vue'
import comOrderRefund from '@/components/miniapp/com-order-refund.vue'
import comUploadBtn from '@/components/public/com-upload-btn.vue'
import { reactive, toRefs } from 'vue'
import goodsModel from '@/api/goods.js'
import outletModel from '@/api/addons/outlet.js'
import store from '@/store'
import {useUpdateOrderPrice, useExportOrder, useChangeOrderStatus} from '@/models/goods'
export default{
	name:'order-list',
	components:{
		comSetRemark,
		comDeliverGoods,
		comPrintFaceOrder,
		comOrderRefund,
		comUploadBtn
	},
	props:{
		isShop:Number
	},
	setup(){
		const _d = reactive({
			search:{
				key:'',
				outlet_id:'',
				recovery_method:'',
				order_status:'-1',
				searchTime:[],
			},
			info:{
				list:[],
				page:1,
				limit:10,
                count:0
			},
			order_id:0,
			outlet:[],
			face:{
				order_id:[],
			}
		})

		getOrderList(1,_d.info.limit)

        //判断当前是否有门店插件
        if( store.state.modulesPerm.salesroom == 1 ){
            outletModel.getOutletList(1,999,null,res=>_d.outlet =res.list)
        }

        function getOrderList(page,limit){
            goodsModel.getOrder(page,limit,_d.search,res=>_d.info= {limit,...res})
        }

        //修改订单价格
        let { uopState ,showUpdatePrice,saveOrderPrice } = useUpdateOrderPrice(_d,getOrderList)

        //导出订单
        let { exportState,exportOrder,outCheckAll } = useExportOrder(_d)

        //订单操作
        let { changeOrderStatus } = useChangeOrderStatus(_d,getOrderList)

        //批量发货
		const uploadChange =(url) => goodsModel.sendOrderBatch(url)

        //导出店铺订单
		const exportOutLetOrder = ()=>goodsModel.exportOrder(_d.search,'outlet')

		return{
			...toRefs(_d),
			uploadChange,
			getOrderList,
			exportOutLetOrder,

            uopState ,
            showUpdatePrice,
            saveOrderPrice,
            exportState,
            exportOrder,
            outCheckAll,
            changeOrderStatus
		}
	},
}
</script>

<style lang="scss">

	.table-header>div{
		background: #f8f8f8;
		padding: 3px 15px;
		font-size: 13px;
	}
	.order-table-th{
		width: 100%;
		display: flex;
		height: 40px;
		background: #f8f8f8;
		font-size: 12px;
		line-height: 40px;
	}
	.table-container{
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		justify-content: center;
		-webkit-justify-content: center;
		-webkit-align-content: space-around;
		align-content: space-around;

	}
	.th-opration{
		position: absolute;
		right: 30px;
	}
	.th-tag{
		margin: 10px 10px;
	}
	.th-create-time{
		margin:0 10px;
		font-weight: bold;
	}
	.th-order-number{
		color: gray;
		margin: 0 10px;
	}
	.th-cover{
		width: 70px;
		height: 70px;
	}
	.goods-name{
		font-size: 12px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: 70%;
		margin-left: 10px;
		height: 100%;
		line-height: 70px;
	}
	.td-container{
		border-right: 1px solid #efefef;
		padding: 10px;
	}
	.buy-count{
		font-size: 12px;
		margin-left: 20px;
		text-align: right;
	}
	.td-info{
		font-size: 12px;
		text-align: center;
		flex-direction:column;
		flex: 1;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-align-items: center;
		align-items: center;
		-webkit-justify-content: center;
		justify-content: center;
		-webkit-flex-direction: column;
		flex-direction: column;
		vertical-align:middle;
	}
	.order-list{
		width: 100%;
		border: 1px solid #efefef;
		margin-top: 20px;
	}
	.order-list:hover{
		border: 1px solid #409EFF;
	}



	.up-address{
		color: #0066CC;
		cursor: pointer;
	}


</style>
