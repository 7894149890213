<template>
	<div id="orderList">
		<page-order-list :is-shop="0"></page-order-list>
	</div>
</template>

<script>
import pageOrderList from '@/components/layout/goods/page-order-list.vue'
import { onBeforeRouteLeave } from 'vue-router'
import store from '@/store'
	export default{
		name:"OrderList",
		components:{
			pageOrderList
		},
		setup(){
			onBeforeRouteLeave((to,from,next)=>{
				let type = to.path == '/order/detail' ? 1 : 2
				store.dispatch("setKeepPage",{name:from.name,type})
		    	next();
			})
		}
	}
</script>

<style lang="scss">

</style>
